<script>
import {defineComponent} from 'vue'
import TranslationInput from "../../elements/translation-input.vue";
import {mapGetters} from "vuex";

export default defineComponent({
    name: "task-template-form",

    emits: ['saved', 'updated'],

    props: {
        taskTemplate: {
            type: Object,
            required: true
        },
    },

    components: {TranslationInput},

    computed: {
        ...mapGetters({
            'loading': 'taskTemplate/loading',
            'states': 'taskTemplate/states',
            'errors': 'taskTemplate/errors',
            types: 'taskType/all',
            priorities: 'taskPriority/all',
            statuses: 'taskStatus/all'
        }),

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },
    },

    data: function () {
        return {
            model: {},
        }
    },

    methods: {

        save: function () {
            const method = this.model.id ? 'update' : 'create'

            const data = Object.assign({}, this.model)

            return this.$store.dispatch(`taskTemplate/${method}`, data).then(saved => {
                this.setModel(saved)
                this.$emit('saved')
                if (method === 'create') {
                    const translationKey = 'routes./master-data/tasks/templates/:id(\\d+)'
                    this.$router.push(this.$t(translationKey, {id: this.model.id}).rmValidation())
                }
            })
        },

        setModel: function (model = {}) {
            this.model = Object.assign({}, JSON.parse(JSON.stringify(model)))
        }

    },

    watch: {
        taskTemplate: function (value) {
            this.setModel(value)
        }
    },

    created() {
        this.setModel(this.taskTemplate)
        this.$store.dispatch('taskPriority/all', true)
        this.$store.dispatch('taskStatus/all', true)
        this.$store.dispatch('taskType/all', true)
    }
})
</script>

<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row mb-4">
                <div class="col-lg-3">
                    <label
                        :class="{'text-danger': states.name === false}"
                        class="form-label"
                        for="name"
                    >{{ $t('tasks::tasks.columns.name').ucFirst() }} <span class="text-primary">*</span></label
                    >
                    <input
                        id="name"
                        v-model="model.name"
                        :class="{'border-danger': states.name === false}"
                        :placeholder="$t('tasks::tasks.placeholders.name').ucFirst()"
                        class="form-control"
                        name="name"
                        type="text"
                    />
                    <div :class="{'d-block': states.name === false}" class="invalid-feedback">{{ errors.name }}</div>
                </div>

                <div class="col-lg-3">
                    <label
                        :class="{'text-danger': states.translation_key === false}"
                        class="form-label"
                        for="translation_key"
                    >{{ $t('tasks::tasks.columns.translation_key').ucFirst() }}</label
                    >
                    <translation-input
                        v-model="model.translation_key"
                        :error="errors.translation_key"
                        :placeholder="$t('tasks::tasks.placeholders.translation_key', model.id ? {attribute: model.name} : {}).ucFirst()"
                        :state="states.translation_key"
                    ></translation-input>
                    <div :class="{'d-block': states.translation_key === false}" class="invalid-feedback">
                        {{ errors.translation_key }}
                    </div>
                </div>
                <div class="col-lg-3">

                    <label
                        :class="{'text-danger': states.description === false}"
                        class="form-label"
                        for="description"
                    >{{ $t('tasks::tasks.columns.description').ucFirst() }}</label
                    >
                    <textarea
                        id="description"
                        v-model="model.description"
                        :class="{'border-danger': states.description === false}"
                        :placeholder="$t('tasks::tasks.placeholders.description').ucFirst()"
                        class="form-control"
                        rows="3"
                    ></textarea>
                    <div :class="{'d-block': states.description === false}" class="invalid-feedback">
                        {{ errors.description }}
                    </div>
                </div>
                <div class="col-lg-3">
                    <label
                        :class="{'text-danger': states.description_translation_key === false}"
                        class="form-label"
                        for="description_translation_key"
                    >{{ $t('tasks::tasks.columns.description_translation_key').ucFirst() }}</label
                    >
                    <translation-input
                        v-model="model.description_translation_key"
                        :error="errors.description_translation_key"
                        :placeholder="$t('tasks::tasks.placeholders.description_translation_key', model.id ? {attribute: model.name} : {}).ucFirst()"
                        :state="states.description_translation_key"
                    ></translation-input>
                    <div :class="{'d-block': states.description_translation_key === false}" class="invalid-feedback">
                        {{ errors.description_translation_key }}
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-lg-3">
                    <label
                        :class="{'text-danger': states.content === false}"
                        class="form-label"
                        for="content"
                    >{{ $t('tasks::tasks.columns.content').ucFirst() }}</label
                    >
                    <textarea
                        id="content"
                        v-model="model.content"
                        :class="{'border-danger': states.content === false}"
                        :placeholder="$t('tasks::tasks.placeholders.content').ucFirst()"
                        class="form-control"
                        rows="3"
                    ></textarea>
                    <div :class="{'d-block': states.content === false}" class="invalid-feedback">
                        {{ errors.content }}
                    </div>
                </div>
                <div class="col-lg-3">
                    <label
                        :class="{'text-danger': states.content_translation_key === false}"
                        class="form-label"
                        for="content_translation_key"
                    >{{ $t('tasks::tasks.columns.content_translation_key').ucFirst() }}</label
                    >
                    <translation-input
                        v-model="model.content_translation_key"
                        :error="errors.content_translation_key"
                        :placeholder="$t('tasks::tasks.placeholders.content_translation_key', model.id ? {attribute: model.name} : {}).ucFirst()"
                        :state="states.content_translation_key"
                    ></translation-input>
                    <div :class="{'d-block': states.content_translation_key === false}" class="invalid-feedback">
                        {{ errors.content_translation_key }}
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.type_id === false}"
                        class="form-label"
                        for="type_id-input"
                    >{{ $t('tasks::tasks.columns.type_id').ucFirst() }}</label
                    >
                    <select
                        id="type_id-input"
                        v-model="model.type_id"
                        :class="{'border-danger': states.type_id === false}"
                        class="form-select"
                        name="type_id"
                    >
                        <option v-if="false" :value="model.type_id === undefined ? undefined : null">
                            {{ $t('tasks::tasks.placeholders.type_id').ucFirst() }}
                        </option>
                        <option v-for="(type, index) in types" :key="index" :value="type.id">
                            {{ type.translation_key ? $t(type.translation_key).ucFirst() : type.name }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.type_id === false}" class="invalid-feedback">{{ errors.type_id }}</div>
                </div>
                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.priority_id === false}"
                        class="form-label"
                        for="priority_id-input"
                    >{{ $t('tasks::tasks.columns.priority_id').ucFirst() }}</label
                    >
                    <select
                        id="priority_id-input"
                        v-model="model.priority_id"
                        :class="{'border-danger': states.priority_id === false}"
                        class="form-select"
                        name="priority_id"
                    >
                        <option v-if="false" :value="model.priority_id === undefined ? undefined : null">
                            {{ $t('tasks::tasks.placeholders.priority_id').ucFirst() }}
                        </option>
                        <option v-for="(priority, index) in priorities" :key="index" :value="priority.id">
                            {{ priority.translation_key ? $t(priority.translation_key).ucFirst() : priority.name }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.priority_id === false}" class="invalid-feedback">{{ errors.priority_id }}</div>
                </div>
                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.status_id === false}"
                        class="form-label"
                        for="status_id-input"
                    >{{ $t('tasks::tasks.columns.status_id').ucFirst() }}</label
                    >
                    <select
                        id="status_id-input"
                        v-model="model.status_id"
                        :class="{'border-danger': states.status_id === false}"
                        class="form-select"
                        name="status_id"
                    >
                        <option v-if="false" :value="model.status_id === undefined ? undefined : null">
                            {{ $t('tasks::tasks.placeholders.status_id').ucFirst() }}
                        </option>
                        <option v-for="(status, index) in statuses" :key="index" :value="status.id">
                            {{ status.translation_key ? $t(status.translation_key).ucFirst() : status.name }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.status_id === false}" class="invalid-feedback">{{ errors.status_id }}</div>
                </div>
            </div>

            <div class="row justify-content-end">
                <div class="col-lg-12">
                    <button class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<style scoped>

</style>
